import React from 'react';
import Price from '../price/price';

const Prices = (props) => {
    return (
    <section className="pricing" id="pricing"  ref={props.ref}>
      <h2 className="heading">{props.title}</h2>
      <p className="pricing__cta-text">{props.ctaText}</p>
      <ul className="box-container">
        {
          props.pricesArray.map( (item) => (
            <Price key={item.key} name={item.name}  description={item.description} price={item.price} />
          ))
        }
      </ul>
    </section>
  )
};

export default Prices;