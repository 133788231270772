import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const Team = (props) => {
  return (
    <section className='team' id='team' ref={props.ref}>
    <h2 className='heading'>{props.sectionTitle}</h2>
        <div className='content'>
            <div className="avatar-container">
                <Link to={props.jamesRoute}>
                    <div className="avatar">
                        <div className='avatar-wrapper'>
                            <StaticImage 
                                src='../../images/james.jpeg'
                                alt='James'
                                formats={["AUTO", "WEBP", "AVIF","SVG"]}
                                quality={95}
                            />
                        </div>
                        <p>James</p>
                    </div>
                </Link>

                <Link to={props.tainaRoute}>
                    <div className="avatar"> 
                        <div className='avatar-wrapper'>
                            <StaticImage 
                                src='../../images/taina.jpeg'
                                alt='Taina'
                                formats={["AUTO", "WEBP", "AVIF","SVG"]}
                                quality={95}
                                style={{ transform: `translateY(-12%)`}}
                            />
                        </div>
                        <p>Taina</p>
                    </div>
                </Link>
            </div>
        </div>
  </section>
  )
}

export default Team;