import React from 'react';

const Price = (props) => {
    return (
        <li className="box">
            <a href="https://timma.fi/yritys/terapeutiset-kadet-hieronta" target="_blank" rel="noreferrer">
                <h3 className="title">{props.name}</h3>
                <p>{props.description}</p>
                <div className="price">{props.price}</div>
            </a>
        </li>
    )
};

export default Price;