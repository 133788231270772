import React, { useRef }  from "react"
import emailjs from '@emailjs/browser';
import '../layout/layout.css';

const isBrowser = typeof window !== "undefined";

const ContactForm = (props) => {

  const form = useRef();
  let name = '';
  let email = '';
  let message = '';
  let btnText = '';
  let submissionText = '';
 
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_cmol2lw', 'template_sji5zzq', form.current, '7kTC2ZTF0dvbzsF_D')
      .then((result) => {
          console.log(result.text); 
      }, (error) => {
          console.log(error.text);
      }); 
      e.target.reset(); 
      alert(submissionText);          
  }

  if(isBrowser) {
    if (window.location.href.indexOf('/en') > -1) {
      name = 'Name:';
      email = 'Email:';
      message = 'Message:';
      btnText = 'Send';
      submissionText = 'Thank you! I will be in touch shortly.';
    } else {
      name = 'Nimi:';
      email = 'Sähköposti:';
      message = 'Viesti:';
      btnText = 'Lähetä';
      submissionText = 'Kiitos! Otan pian yhteyttä.';
    }
  }
 
  return (
    <section className='contact' id='contact' ref={props.ref}>
      <h2 className='heading'>{props.formTitle}</h2>
      <p>{props.OpeningTimeTitle}</p>
      <p>{props.Weekdays}</p>
      <p>{props.Weekends}</p>
      <div className="contact-content">
        <div className="iframe-container">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1668.6107954658!2d24.95810066396685!3d60.17537205696436!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bd120f22ebb%3A0x41d2c9a6ec3c2a9b!2sMeritullinkatu%2033%2C%2000170%20Helsinki!5e0!3m2!1sen!2sfi!4v1672039475406!5m2!1sen!2sfi"
            width="600" 
            height="450" 
            allowFullScreen="" 
            loading="lazy"
            title="Terapeutiset Kädet Hieronta location in Helsinki" 
            referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
        <form className='contact-form' ref={form} onSubmit={sendEmail} id='form'>
          <h3 className='heading'>{props.formCTA}</h3>
          <div className='inputBox'>
            <label htmlFor='name'>{name}</label>
            <input type='text' name='user_name' id='name' required />
          </div>
          <div className='inputBox'>
            <label htmlFor='email'>{email}</label>
            <input type='email' name='user_email' id='email' required />
          </div>
          <div className='inputBox'>
            <label htmlFor='message'>{message}</label>
            <textarea name='message' id='message' type='text' cols='30' rows='10' required />
          </div>
          <div className='inputBox'>
            <button type='submit' className='btn submit'>{btnText}</button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default ContactForm;