import React from 'react';

const Home = (props) => {
  return (
    <section className="home" id='home' ref={props.ref}>
        <div className="home-content">
            <h1>{props.title}</h1>
        </div>
        <div className="home-cta-container">
            <a className="cta-btn" href="https://timma.fi/yritys/terapeutiset-kadet-hieronta" target="_blank" rel="noreferrer">{props.bookingbtn}</a>
        </div>
    </section>
  )
};

export default Home;