import React, { createRef } from 'react';
import Layout from '../components/layout/layout';
import Home from '../components/home/home';
import Navigation from '../components/navigation/navigation';
import About from '../components/about/about';
import Team from '../components/team/team';
import Prices from '../components/prices/prices';
import Footer from '../components/footer/footer';
import Parallax1 from '../components/parallax1';
import Parallax2 from '../components/parallax2';
import ContactForm from '../components/contactForm/contactForm';

const IndexPage = () => {

  const {home, about, team, pricing, contact, footer} = createRef;
  const pricesArray = [
    {
      key: 1,
      name: 'Klassinen hieronta (30min)',
      description: 'Klassinen hieronta on lihaksia monipuolisesti muokkaavaa ja rentouttavaa hierontaa.',
      price: '35€'
    },
    {
      key: 2,
      name: 'Klassinen hieronta (45min)',
      description: 'Klassinen hieronta on lihaksia monipuolisesti muokkaavaa ja rentouttavaa hierontaa.',
      price: '40€'
    },
    {
      key: 3,
      name: 'Klassinen hieronta (60min)',
      description: 'Klassinen hieronta on lihaksia monipuolisesti muokkaavaa ja rentouttavaa hierontaa.',
      price: '55€'
    },
    {
      key: 4,
      name: 'Opiskelija-alennus -20% Massage 60 min',
      description: 'Klassinen hieronta on lihaksia monipuolisesti muokkaavaa ja rentouttavaa hierontaa.',
      price: '44€'
    },
    {
      key: 5,
      name: 'Opiskelija-alennus -20% Massage 45 min',
      description: 'Klassinen hieronta on lihaksia monipuolisesti muokkaavaa ja rentouttavaa hierontaa.',
      price: '36€'
    },
    {
      key: 6,
      name: 'Opiskelija-alennus -20% Massage 30 min',
      description: 'Klassinen hieronta on lihaksia monipuolisesti muokkaavaa ja rentouttavaa hierontaa.',
      price: '28€'
    }
  ]
  const aboutTextArray = [
    {
      key: 1,
      content: `Tervetuloa Terapeuttiset kädet hierontaan. Olemme erittäin hyvin koulutetut alan
      ammattilaiset ja tarjoamme laadukasta hierontaa
      erilaisiin tarpeisiin. Tarjoamme klassista
      hierontaa, urheiluhierontaa, nivelliikkuvuuden
      edistämistä, passiivisia ja dynaamisia venytyksiä,
      kuumakivi hierontaa, kuivakuppausta, kinesio
      teippausta, perusvenytys ohjeita kotiin
      tukemaan lihas - ja nivelliikkuvuutta hieronnan
      tukena. Hieromme asiakkaita säädettävillä
      hierontapöydillä jossa asiakas on
      makuuasennossa. Tarjoamme hierontaa myös
      istuen sitä varten tehdyssä ns. hierontatuolissa
      jossa voidaan olla myös vaatteet päällä.`,
    },
    {
      key: 2,
      content: `Tiimimme tarkoitus on tarjota ylläolevia
      palveluita asiakaslähtöisesti varmistaaksemme
      parhaan mahdollisen lopputuloksen hoidolle yksilökohtaisesti aina asiakkaan toiveet
      huomioiden.`,
    },
    {
      key: 3,
      content: `Meil voit maksaa kaikissa muodoissa kuten esim.
      käteisellä, korteilla, Smartumilla, Epassilla ja
      Endredillä.`,
    },
    {
      key: 4,
      content: `ID/Y -tunnus: 33138073`,
    },
    {
      key: 5,
      content: `Tarjoamme alennuksia opiskelijoille, eläkeläisille
      ja kuukausittaisille kävijöille. Tarvittaessa voit
      kysyä lisätietoa meiltä näistä.`,
    },
    {
      key: 6,
      content: `Hieronta tarjoaa monia etuja kuten vähentää
      lihaskipuja, rentouttaa, vähentää stressiä ja
      parantaa henkistä hyvinvointia.
      Hierontatekniikat vaihtelet tarpeen mukaan
      hellästä lihasten suuntaisista rauhallisista
      liikkeistä jota yleensä kutsutaan klassiseksi
      hieronnaksi ja siitä voimakkaampiin otteisiin jolla
      päästään käsittelemään kudoksia syvemmin..`,
    }
  ]

  return (
      <Layout>
        <title>Koti Sivu</title>
          <Navigation
            homeRoute={`/`}
            aboutRoute={`#about`}
            teamRoute={`#team`}
            pricingRoute={`#pricing`}
            contactRoute={`#contact`}
            contactTitle={'Ota yhteyttä'}
            langLink={`EN`}
            contactLink={`OTA YHTEYTTÄ`}
            langTitle={'Englanninkielinen sivu'}
            navId={'kotisivu'}
            language={`/en`}
            nav={`#yhteystiedot`}
            home={`Alku`}
            about={`Meistä`}
            team={`Tiimi`}
            pricing={`Hinnasto`}
            contact={`Yhteystiedot`}
          />
          <Home 
            ref={home}
            title={`Terapeuttiset Kädet Hieronta`} 
            bookingbtn={`Varaa Aikasi`}
          />
          <main>
            <ContactForm 
              formTitle={`Ota Yhteyttä`} 
              formCTA={`Jätä Viesti`} 
              OpeningTimeTitle={`Aukioloajat`}
              Weekends={`la: 9:00-22:00`}
              Weekdays={`ma-pe: 09:00-22:00`}
              ref={contact} />
            <About 
              sectionTitle={`Meistä`} 
              ref={about}
              aboutTextArray={aboutTextArray}
            />
            <Parallax1/>
            <Team 
              sectionTitle={`Tiimi`} 
              jamesRoute={`/james`}
              tainaRoute={`/taina`}
              ref={team}
            />
            <Prices title={`Hinnat`} ref={pricing} pricesArray={pricesArray} ctaText={`Klikkaa ja varaa aikasi`} />
            <Parallax2/>
          </main>
          <Footer followMe={`Seuraa meitä`} contactInfo={`Yhteystiedot`} ref={footer}/>
     </Layout>
  )
}

export default IndexPage;